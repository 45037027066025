<template>
    <div class="register-component" :class="{ integratedModalBg: integratedModalBg && !external && promotion == null, hasWelomeOffer: selectedWelcomeOffer }">
        <div class="selected-welcome-offer" v-if="!external && integratedModalBg && promotion == null && selectedWelcomeOffer && (callsStore.countryCode !== 'se' || configStore.config.allowBonusesInSE)">
            <h4 class="p-1" v-html="getDictionary(null, selectedWelcomeOffer.offer)"></h4>
        </div>
        <div class="register-container">
            <!-- <h3 class="modal-title mb-3 brand-font" v-if="!external">
                <small v-html="getDictionary('REGISTER')"></small>
            </h3> -->
            <p v-if="registerBlocked" class="my-5"><span v-html="getDictionary('REGISTER_BLOCKED')"></span> {{ configStore.config.originalName }}.</p>
            <template v-else>
                <div class="banner-register" v-if="!external && (!integratedModalBg || promotion) && selectedWelcomeOffer && (callsStore.countryCode !== 'se' || configStore.config.allowBonusesInSE)" :style="{ backgroundImage: `url(${promoOrBannerImageUrl(promotion ? promotion : selectedWelcomeOffer)}` }">
                    <span v-html="getDictionary(null, selectedWelcomeOffer.offer)"></span>
                </div>
                <register-functionality :external="external" />
            </template>
        </div>
    </div>
</template>

<script>
import RegisterFunctionality from "../register/registerFunctionality.vue";
import FrDigitalZipCodesToBlock from "../../assets/frDigitalZipCodesToBlock";
import NlDigitalZipCodesToBlock from "../../assets/nlDigitalZipCodesToBlock";
import { pushGtmEvent } from "../../helpers/gtm_helpers";
import { deviceInfo, promoOrBannerImageUrl, getCookie } from "../../helpers/helpers";

export default {
    name: "register-component",
    data: function () {
        return {
            registerBlocked: false,
        };
    },
    props: {
        external: Boolean,
        promotion: Object,
    },
    components: {
        RegisterFunctionality,
    },
    computed: {
        selectedWelcomeOffer() {
            if (this.perCallsStore.promotionsObject && this.perCallsStore.promotionsObject.selectedWelcomeOffer) {
                return this.perCallsStore.promotionsObject.selectedWelcomeOffer;
            }
        },
        integratedModalBg() {
            return this.configStore.config.integratedModalBg;
        },
    },
    created() {
        this.myStore.setModalHeaderTitle("");
        this.isRegisterBlocked();
    },
    mounted() {
        if (getCookie("affiliateId") !== "") {
            pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Opened" });
        }
    },
    methods: {
        promoOrBannerImageUrl(item) {
            return promoOrBannerImageUrl(item, true);
        },
        isRegisterBlocked() {
            const locationObject = this.callsStore.locationObject;
            if (import.meta.env.VITE_ENV === "prod" && locationObject && locationObject.country) {
                // getDay() returns an integer number, between 0 and 6, corresponding to the day of the week for the given date, according to local time: 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.
                const currentDay = new Date().getDay();
                const currentHour = new Date().getHours();
                const skin = this.configStore.config.skin;

                if (locationObject.country.code === "MT" || (locationObject.country.code === "CY" && (skin === "ac" || skin === "bz" || skin === "ra"))) {
                    // MT Blocking - OR - Purple Bay brands from Cyprus Blocking
                    this.registerBlocked = true;
                    this.myStore.setBlockedRegister(true);
                } else if (locationObject.country.code === "NL" && locationObject.postcode && currentDay !== 0 && currentDay !== 6 && currentHour > 6 && currentHour < 19 && deviceInfo().isMobile === false) {
                    // NL Blocking - Monday to Friday between 6AM to 7PM and using Desktop
                    const zipCode = locationObject.postcode;
                    if (NlDigitalZipCodesToBlock.indexOf(zipCode) !== -1) {
                        if (getCookie("affiliateId") !== "") {
                            pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Blocked" });
                        }
                        this.registerBlocked = true;
                        this.myStore.setBlockedRegister(true);
                    }
                    // } else if (locationObject.country.code === "FR" && currentDay !== 0 && currentDay !== 6 && currentHour > 6 && currentHour < 19 && navigator.userAgent.search("Firefox") !== -1) {
                } else if (locationObject.country.code === "FR" && currentDay !== 0 && currentDay !== 6 && currentHour > 6 && currentHour < 19 && deviceInfo().isMobile === false) {
                    // FR Blocking - Monday to Friday between 6AM to 7PM and using Desktop
                    if (getCookie("affiliateId") !== "") {
                        pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Blocked" });
                    }
                    this.registerBlocked = true;
                    this.myStore.setBlockedRegister(true);
                    // if (locationObject.postcode) {
                    //     const zipCode = locationObject.postcode;
                    //     if (FrDigitalZipCodesToBlock.indexOf(zipCode) !== -1) {
                    //         if (getCookie("affiliateId") !== "") {
                    //             pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "Register_Blocked" });
                    //         }
                    //         this.registerBlocked = true;
                    //         this.myStore.setBlockedRegister(true);
                    //     }
                    // }
                }
            }
        },
    },
};
</script>

<style lang="scss">
.register-component {
    .selected-welcome-offer {
        text-shadow: 1px 1px 2px #000, 0 0 1em #000, 0 0 0.2em #000, 0 0 1em #000, 0 0 0.2em #000;
        text-align: center;
        color: #fff;
    }

    .banner-register {
        background-color: var(--darken-site-background);
        position: relative;
        margin: 10px auto;
        background-position: center center;
        height: 180px;
        max-width: 600px;
        background-size: cover;

        span {
            background-color: var(--game-hover);
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            color: var(--game-hover-text);
            width: 100%;
            font-size: 16px;
            padding: 5px;
        }

        @media only screen and (min-width: 500px) {
            span {
                font-size: 20px;
            }
        }

        @media only screen and (min-height: 750px) {
            height: 240px;
        }
    }
}
</style>
