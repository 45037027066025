<template>
    <div class="register-functionality-component pt-3">
        <div v-if="myStore.closeRegisterAttempt && perCallsStore.promotionsObject">
            <p class="my-2" v-if="perCallsStore.promotionsObject.selectedWelcomeOffer && (callsStore.countryCode !== 'se' || configStore.config.allowBonusesInSE)">
                <strong v-html="getDictionary('REGISTER_CLOSE_TEXT')"></strong>
            </p>
            <p>
                <strong v-html="getDictionary('REGISTER_TRY_US')"></strong>
            </p>
            <div class="btn-group wide">
                <button type="button" class="btn skin-btn second" @click="closeRegisterAttempt('close')" v-html="getDictionary('CLOSE')"></button>
                <button type="button" class="btn skin-btn no-border" @click="closeRegisterAttempt('back')" v-html="getDictionary('BACK')"></button>
            </div>
        </div>
        <template v-else>
            <div class="d-flex register-progress" v-if="!external">
                <font-awesome-icon class="icon green" icon="fa-solid fa-1" :class="{ active: step === 1 }" />
                <div class="line" :class="{ orange: step === 2 }">
                    <div class="green" :style="{ width: step1Progress }"></div>
                </div>
                <font-awesome-icon class="icon" :class="{ green: step === 2 || step2Progress !== '0%', active: step === 2 }" icon="fa-solid fa-2" />
                <div class="line" :class="{ orange: step2Progress !== '0%' && step === 1 }">
                    <div class="green" :style="{ width: step2Progress }"></div>
                </div>
                <font-awesome-icon class="icon" :class="{ green: step1Progress === '100%' && step2Progress === '100%' }" icon="fa-solid fa-clipboard-check" :shake="step1Progress === '100%' && step2Progress === '100%'" @click="register()" />
            </div>
            <form ref="registerForm">
                <template v-if="step === 1">
                    <form-field type="text" :v$="v$.step1.email" fieldName="EMAIL" />
                    <form-field type="password" :v$="v$.step1.password" fieldName="PASSWORD" autocomplete="new-password" />
                    <form-field type="text" :v$="v$.step1.firstName" fieldName="NAME" />
                    <form-field type="text" :v$="v$.step1.lastName" fieldName="SURNAME" />
                    <!-- <div class="my-row" v-if="!external">
                        <div class="col-4"><select-field-long :v$="v$.step1.dobDay" fieldName="BIRTH_DAY" margin="yes" :year="this.step1.dobYear" :month="this.step1.dobMonth" /></div>
                        <div class="col-4"><select-field-long :v$="v$.step1.dobMonth" fieldName="MONTH" margin="yes" /></div>
                        <div class="col-4"><select-field-long :v$="v$.step1.dobYear" fieldName="YEAR" /></div>
                    </div> -->
                    <div class="my-row" v-if="!external">
                        <div class="col-4"><form-field type="number" :v$="v$.step1.dobDay" fieldName="BIRTH_DAY" margin="yes" /></div>
                        <div class="col-4"><form-field type="number" :v$="v$.step1.dobMonth" fieldName="MONTH" margin="yes" /></div>
                        <div class="col-4"><form-field type="number" :v$="v$.step1.dobYear" fieldName="YEAR" /></div>
                    </div>
                    <button type="button" class="btn skin-btn wide mt-2" @click="step = 2" v-if="!external" v-html="getDictionary('NEXT')"></button>
                </template>
                <template v-if="step === 2">
                    <form-field type="text" :v$="v$.step2.address1" fieldName="ADDRESS" />
                    <form-field type="text" :v$="v$.step2.city" fieldName="CITY" />
                    <form-field type="text" :v$="v$.step2.postcode" fieldName="POSTCODE" />
                    <div class="my-row">
                        <div class="col-5"><select-field-long :v$="v$.step2.dialCode" fieldName="DIAL_CODE" :location="callsStore.locationObject" :options="perCallsStore.countriesArray" margin="yes" /></div>
                        <div class="col-7"><form-field type="number" :v$="v$.step2.phone" fieldName="PHONE" /></div>
                    </div>
                    <p class="conditions mt-4 text-start"><span v-html="getDictionary('REGISTER_TAC')"></span> <router-link :to="getPageBySlug('general-terms-conditions')" v-html="getDictionary('TAC')"></router-link></p>
                    <div class="btn-group wide my-2">
                        <button type="button" class="btn skin-btn second" @click="step = 1" v-html="getDictionary('BACK')"></button>
                        <button type="button" class="btn skin-btn no-border" @click="register()" :disabled="status === 'PENDING'">
                            <font-awesome-icon v-if="status === 'PENDING'" icon="fa-solid fa-spinner" spin pulse />
                            <template v-else><span v-html="getDictionary('REGISTER')"></span> <font-awesome-icon class="icon" icon="fa-solid fa-clipboard-check" :shake="step1Progress === '100%' && step2Progress === '100%'" /></template>
                        </button>
                    </div>
                    <strong class="secure m-5"><font-awesome-icon icon="fa-solid fa-shield-check" /> <span v-html="getDictionary('SECURE_100')"></span></strong>
                </template>
            </form>
        </template>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, maxLength, numeric, minValue, maxValue } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import FormField from "../dynamic/others/formField.vue";
import SelectField from "../dynamic/others/selectField.vue";
import SelectFieldLong from "../dynamic/others/selectFieldLong.vue";
import { getBrandCountries, register } from "../../services/tma/axiosTmaRequests";
import { insertUserIp, getUserIp } from "../../services/strapi/axiosStrapiRequests";
import { setModalVisibility, debounce, cleanDataRegex, getCookie, getPageBySlug } from "../../helpers/helpers";
import * as Sentry from "@sentry/vue";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    name: "register-functionality-component",
    props: {
        external: Boolean,
    },
    data: function () {
        return {
            step: 1,
            step1Progress: "0%",
            step2Progress: "0%",
            emailVerified: false,
            phoneVerified: false,
            status: "",
            country: null,
            currency: null,
            step1: {
                email: null,
                password: null,
                firstName: null,
                lastName: null,
                dobDay: null,
                dobMonth: null,
                dobYear: null,
            },
            step2: {
                address1: null,
                city: null,
                postcode: null,
                dialCode: null,
                phone: null,
            },
        };
    },
    validations() {
        return {
            step1: {
                email: {
                    required,
                    email,
                    // existingEmail: helpers.withAsync(() => {
                    //     if (this.emailVerified === false || this.v$.step1.email.$invalid === false) {
                    //         this.emailVerified = true;
                    //         return userExists(this.configStore.config.skin, this.step1.email, this.myStore);
                    //     }
                    //     return true;
                    // }),
                },
                password: {
                    required,
                    minLength: minLength(8),
                    maxLength: maxLength(50),
                    passwordStrength: function (value) {
                        if (typeof value === "undefined" || value === null || value === "") {
                            return true;
                        }
                        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/.test(value);
                    },
                },
                firstName: { required, maxLength: maxLength(100), cleanDataRegex },
                lastName: { required, maxLength: maxLength(100), cleanDataRegex },
                dobDay: { required, numeric, minValue: minValue(1), maxValue: maxValue(31), maxLength: maxLength(2) },
                dobMonth: { required, minValue: minValue(1), maxValue: maxValue(12), numeric, maxLength: maxLength(2) },
                dobYear: { required, numeric, minValue: minValue(new Date().getFullYear() - 118), maxValue: maxValue(new Date().getFullYear() - 18) },
            },
            step2: {
                address1: { required, minLength: minLength(5), maxLength: maxLength(250), cleanDataRegex },
                city: { required, minLength: minLength(3), maxLength: maxLength(70), cleanDataRegex },
                postcode: { required, minLength: minLength(3), maxLength: maxLength(10), cleanDataRegex },
                dialCode: { required },
                phone: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(20),
                    // existingPhone: helpers.withAsync(() => {
                    //     if (this.phoneVerified === false || this.v$.step2.phone.$invalid === false) {
                    //         this.phoneVerified = true;
                    //         return userExists(this.configStore.config.skin, null, `+${this.step2.dialCode.dialCode} ${this.step2.phone}`, this.myStore);
                    //     }
                    //     return true;
                    // }),
                },
            },
        };
    },
    components: {
        FormField,
        SelectField,
        SelectFieldLong,
    },
    updated() {
        this.debounceUpdate();
    },
    created() {
        if (this.configStore.registerData) {
            // If user started the register process but has not registered yet
            // We populate the register data with the previously entered data stores in the local storage
            Object.keys(this.configStore.registerData).forEach((key) => {
                key in this.step1 ? (this.step1[key] = this.configStore.registerData[key]) : null;
                key in this.step2 ? (this.step2[key] = this.configStore.registerData[key]) : null;
            });
        }
        getBrandCountries(this.perCallsStore, this.configStore.config.skin, this.myStore, this.callsStore, this.$router);

        const countryCode = this.callsStore.locationObject ? this.callsStore.locationObject.country.code.toLowerCase() : "gb";
        // It was decided that we don't show the currency anymore and the currency is automatiacally populated
        this.currency = "EUR";
        this.configStore.config.countries.map((country) => {
            if (country.code.toLowerCase() === countryCode && country.defaultCurrency) {
                this.currency = country.defaultCurrency;
            }
        });

        setTimeout(
            function () {
                // It was decided that we don't show the country anymore and the country is automatiacally sent from the location object
                if ((this.configStore.config.skin === "bo" || this.configStore.config.skin === "lj") && this.callsStore.countryCode === "nl") {
                    this.country = { code: "FI", dialCode: 358, minimumAge: 18, name: "Finland" };
                } else {
                    this.country = this.callsStore.locationObject.country;
                }
            }.bind(this),
            7000,
        );

        this.debounceUpdate = debounce(() => {
            // Calculating Register progress + storing the entered data in local storage
            if (this.step === 1) {
                this.step1Progress = this.stepProgress("step1");
                this.configStore.setRegisterData(this.step1);
            } else {
                this.step2Progress = this.stepProgress("step2");
                this.configStore.setRegisterData(this.step2);
            }
        }, 400);

        window.addEventListener(
            "keypress",
            function (event) {
                if (event.key === "Enter" && this.$route.name === "Register" && this.step === 2) {
                    event.preventDefault();
                    this.register();
                }
            }.bind(this),
        );
    },
    mounted() {
        this.$refs.registerForm.focus();
    },
    methods: {
        getPageBySlug(slug) {
            return getPageBySlug(slug, this.perCallsStore.pagesObject, this.configStore.config.skin);
        },
        stepProgress(step) {
            let noOfProps = 0;
            let noOfGoodProps = 0;
            Object.keys(this[step]).map((key) => {
                noOfProps++;
                this.v$[step][key].$invalid ? noOfGoodProps : noOfGoodProps++;
            });
            return this.stepPercentage(noOfProps, noOfGoodProps);
        },
        stepPercentage(noOfProps, noOfGoodProps) {
            let percentage = (100 / noOfProps) * noOfGoodProps;
            return Math.round(percentage > 100 ? 100 : percentage) + "%";
        },
        closeRegisterAttempt(action) {
            this.myStore.setCloseRegisterAttempt(false);
            if (action === "close") {
                setModalVisibility(false, this.$router);
            }
        },
        async register() {
            this.status = "PENDING";

            // if (this.v$.step1.email.$invalid || this.v$.step2.phone.$invalid) {
            //     this.myStore.setAlert({ text: "USER_ALREADY_EXISTS", classes: "error" });
            //     this.status = "";
            //     return;
            // }

            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                if (this.v$.step1.$invalid) {
                    this.step = 1;
                }
                this.status = "";
                return;
            }

            const twoDaysAgo = new Date(new Date().setDate(new Date().getDate() - 2)).toISOString();
            getUserIp(this.myStore, this.configStore.config.skin, this.step1.email, this.callsStore.locationObject.ip, twoDaysAgo).then((response) => {
                if (response.userIps && response.userIps.length > 0) {
                    // User already registered in the past two days
                    Sentry.setTag("Duplicate Ip", this.callsStore.locationObject.ip);
                    Sentry.withScope(
                        function (scope) {
                            scope.setLevel("info");
                            Sentry.captureMessage(`Duplicate Account Block - ${this.configStore.config.skin} - ${this.step1.email} from ${this.callsStore.locationObject.ip}`);
                        }.bind(this),
                    );
                    this.myStore.setAlert({ text: "USER_ALREADY_EXISTS", classes: "error" });
                    this.configStore.setRegisterData(null);
                    this.status = "FAILED";
                } else {
                    let dobD = this.step1.dobDay.toString();
                    dobD = dobD.length === 1 ? `0${dobD}` : dobD;
                    let dobM = this.step1.dobMonth.toString();
                    dobM = dobM.length === 1 ? `0${dobM}` : dobM;
                    const dobY = this.step1.dobYear.toString();
                    let dob = new Date(`${dobY}-${dobM}-${dobD}`);

                    let skin = this.configStore.config.skin;
                    let countryCode = this.country.code;
                    let receiveEmail = true;
                    let receivePhone = true;
                    let receiveSms = true;
                    if (skin === "zu") {
                        skin = "lj";
                        countryCode = "GH";
                        receiveEmail = false;
                        receivePhone = false;
                        receiveSms = false;
                    } else if (skin === "nw") {
                        skin = "ra";
                    }

                    let queryInput = `
                        brandId: "${skin}",
                        password: "${this.step1.password}",
                        email: "${this.step1.email}"
                        firstName: "${this.step1.firstName}",
                        lastName: "${this.step1.lastName}",
                        birthDate: "${dob}",
                        affiliateId: "${getCookie("affiliateId")}",
                        affiliateTag:"${getCookie("affiliateToken")}",
                        localeCode:"${getCookie("languageCode")}",
                        address1: "${this.step2.address1}",
                        city: "${this.step2.city}",
                        postcode: "${this.step2.postcode}",
                        countryCode: "${countryCode}",
                        currencyCode: "${this.currency}",
                        receiveEmail: ${receiveEmail},
                        receivePhone: ${receivePhone},
                        receiveSms: ${receiveSms},
                        redirectUri: "${document.location.origin}/email-verification/:code"
                    `;

                    if (this.configStore.config.skin !== "zu") {
                        queryInput = `${queryInput}, phone: "+${this.step2.dialCode.dialCode} ${this.step2.phone}"`;
                    }

                    console.log(`{${queryInput}}`);

                    register(`{${queryInput}}`, this.myStore, this.callsStore, this.$router, this.configStore.config, this.perCallsStore).then((response) => {
                        if (response.success) {
                            if (this.configStore.config.skin === "zu") {
                                // -----EMAIL VERIFICATION ONLY-----
                                setModalVisibility(false, this.$router);
                                this.myStore.setAlert({ text: "SUCCESS_REGISTER", classes: "success" });
                            } else {
                                // -----EMAIL OR SMS VERIFICATION-----
                                this.$router.push("/account-verification");
                            }

                            this.status = "SUCCESS";
                            this.configStore.setRegisterData(null);
                            insertUserIp(this.step1.email, this.callsStore.locationObject.ip, this.configStore.config.skin);
                        } else {
                            this.status = "FAILED";
                        }
                    });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.register-functionality-component {
    .register-progress {
        align-items: center;
        .icon {
            border: 3px solid var(--form-color);
            padding: 4px;
            border-radius: 3px;
            width: 60px;
            height: 17px;
            color: var(--form-highlight-color);
            &.green {
                border: 3px solid var(--form-highlight-color);
            }
            &.active {
                color: var(--form-highlight-color);
            }
        }
        .line {
            height: 3px;
            width: 100%;
            background-color: var(--form-color);
            &.orange {
                background-color: var(--form-missing-highlight-color);
            }
            .green {
                height: 3px;
                background-color: var(--form-highlight-color);
            }
        }
    }
    .conditions {
        font-weight: bold;
        font-size: 11px;
    }
}
</style>
