<template>
    <div class="login-component mt-3">
        <template v-if="twoFAScreen">
            <font-awesome-icon icon="fa-solid fa-sms" class="fa-2xl" /><br />
            <p class="brand-font" v-html="getDictionary('VERIFY_SMS')"></p>
            <p v-html="getDictionary('TWOFA_CODE')"></p>
            <div class="col-12">
                <form-field type="input" :v$="v$.twoFACode" fieldName="SMS_CODE" />

                <div class="btn-group wide mt-3">
                    <button type="button" class="btn skin-btn second no-border" @click="goBack()" v-html="getDictionary('BACK')"></button>
                    <button type="button" class="btn skin-btn" @click="verifyTwoFACode()" v-html="getDictionary('VERIFY_CODE')"></button>
                </div>
            </div>
        </template>
        <template v-else>
            <form>
                <div class="my-row" v-if="configStore.config.twoFA">
                    <div class="col-5"><select-field-long :v$="v$.dialCode" fieldName="DIAL_CODE" :location="callsStore.locationObject" :options="perCallsStore.countriesArray" /></div>
                    <div class="col-7"><form-field type="number" :v$="v$.phone" fieldName="PHONE" /></div>
                </div>
                <form-field v-else type="input" :v$="v$.email" fieldName="EMAIL" ref="input" />
                <form-field type="password" :v$="v$.password" fieldName="PASSWORD" autocomplete="current-password" />
            </form>
            <button type="button" class="btn skin-btn wide my-2" @click="login()" :disabled="status === 'PENDING'">
                <font-awesome-icon v-if="status === 'PENDING'" icon="fa-solid fa-spinner" spin pulse />
                <span v-else v-html="getDictionary('LOGIN')"></span>
            </button>

            <div class="my-row">
                <div class="col-12 col-sm-6 text-start">
                    <router-link class="col link text-start" to="/forgot-password" v-html="getDictionary('FORGOT_PASSWORD')"></router-link>
                </div>
                <div class="col-12 col-sm-6 text-start text-sm-end">
                    <router-link class="col link text-end" to="/register" v-html="getDictionary('REGISTER')"></router-link>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { login, getUserCampaignBonuses, lastSucceededDeposit, getBrandCountries, getUserBox } from "../../services/tma/axiosTmaRequests";
import { getPromotions, getBannerItems } from "../../services/strapi/axiosStrapiRequests";
import { twoFA } from "../../services/axiosInstances";
import { setModalVisibility, getCookie, createCookie, internalServicesUrl } from "../../helpers/helpers";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import FormField from "../dynamic/others/formField.vue";
import SelectFieldLong from "../dynamic/others/selectFieldLong.vue";
import { pushGtmEvent } from "../../helpers/gtm_helpers";
import * as Sentry from "@sentry/vue";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    name: "login-component",
    data: function () {
        return {
            status: "",
            email: null,
            password: null,
            dialCode: null,
            phone: null,
            twoFAScreen: false,
            twoFACode: null,
            twoFAServerCode: null,
        };
    },
    validations() {
        return {
            email: { required, email },
            password: { required },
            dialCode: { required },
            phone: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(20),
            },
            twoFACode: { required },
        };
    },
    components: {
        FormField,
        SelectFieldLong,
    },
    created() {
        this.myStore.setModalHeaderTitle("LOGIN");
        if (getCookie("affiliateId") !== "") {
            pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "Login_Opened" });
        }
        window.addEventListener(
            "keypress",
            function (event) {
                if (event.key === "Enter" && this.$route.name === "Login") {
                    event.preventDefault();
                    this.login();
                }
            }.bind(this),
        );

        if (this.configStore.config.twoFA) {
            getBrandCountries(this.perCallsStore, this.configStore.config.skin, this.myStore, this.callsStore, this.$router);
        }
    },
    methods: {
        goBack() {
            this.twoFAScreen = false;
            this.twoFACode = null;
        },
        async login() {
            this.status = "PENDING";
            let isFormCorrect = await this.v$.$validate();
            if (this.configStore.config.twoFA && (this.v$.dialCode.$invalid || this.v$.phone.$invalid || this.v$.password.$invalid)) {
                this.status = "";
                return;
            } else if (this.configStore.config.twoFA === false && (this.v$.email.$invalid || this.v$.password.$invalid)) {
                this.status = "";
                return;
            }

            if (this.configStore.config.twoFA) {
                twoFA(`+${this.dialCode.dialCode} ${this.phone}`, internalServicesUrl(this.configStore.config.originalDomain), this.myStore).then((response) => {
                    if (response.success && response.code) {
                        this.twoFAScreen = true;
                        this.twoFAServerCode = response.code;
                    } else {
                        this.status = "";
                        return;
                    }
                });
            } else {
                this.doActualLogin(this.email);
            }
        },
        verifyTwoFACode() {
            if (this.twoFACode === this.twoFAServerCode.toString()) {
                this.twoFAScreen = false;
                this.doActualLogin(`+${this.dialCode.dialCode} ${this.phone}`);
            } else {
                this.myStore.setAlert({ text: "TWOFA_CODE_ERROR", classes: "error" });
                this.twoFACode = null;
            }
        },
        doActualLogin(username) {
            login(this.configStore.config, username, this.password, this.callsStore, this.myStore, this.$router, this.perCallsStore).then((response) => {
                if (response.success) {
                    if (this.myStore.loggedIn && this.callsStore.userObject) {
                        Sentry.setContext("Additional Data", {
                            LoggedIn: this.myStore.loggedIn,
                            User: this.myStore.loggedIn ? this.callsStore.userObject : "No User Info",
                        });
                        Sentry.setUser({ email: this.callsStore.userObject.email });
                    }
                    setModalVisibility(false, this.$router);
                    this.myStore.setAlert({ text: "SUCCESS_LOGIN", classes: "success" });

                    lastSucceededDeposit(getCookie("userId"), this.myStore, this.callsStore, getCookie("accessToken"), this.$router, this.configStore.config.skin, this.perCallsStore).then((response) => {
                        if (response.data[0] && response.data[0].node && response.data[0].node.paymentId) {
                            createCookie("depositedCustomer", "true", 1000);
                        } else {
                            createCookie("depositedCustomer", "false", 1000);
                        }
                    });

                    getUserCampaignBonuses(getCookie("userId"), this.configStore.config.skin, this.myStore, getCookie("accessToken"), this.perCallsStore, this.callsStore, this.$router).then((response) => {
                        if (response.success && response.campaignIdList) {
                            getPromotions(this.perCallsStore, this.callsStore, response.campaignIdList, this.configStore.config.skin, this.$router, this.myStore);
                            getBannerItems(this.perCallsStore, this.configStore.config.skin, this.myStore, this.callsStore, response.campaignIdList);

                            if (this.configStore.config.wheelOfFortune) {
                                getUserBox(this.configStore.config.skin, this.myStore, this.perCallsStore, this.callsStore, this.$router);
                            }

                            // Lobby Games Filtering
                            if (
                                (this.callsStore.countryCode === "fr" || this.callsStore.countryCode === "gb") &&
                                this.configStore.config.blockPPGames &&
                                (response.campaignIdList.indexOf("w6qIZpewJGN6sGRsQWkx") !== -1 || response.campaignIdList.indexOf("Uib4UmSto6HjaYgNSZ3G") !== -1 || response.campaignIdList.indexOf("81P8tZuIWHAM1p4fduTq") !== -1 || response.campaignIdList.indexOf("MSUkK345Xcik517jfj63") !== -1 || response.campaignIdList.indexOf("pvVfu4oyAXDdsCqdvstF") !== -1)
                            ) {
                                this.callsStore.setFilterGameProvidersCommand("unblock");

                                // As we unblocked everything in case of GB we need to block Evo again
                                if (this.callsStore.countryCode !== "fr") {
                                    setTimeout(
                                        function () {
                                            this.callsStore.setFilterGameProvidersCommand("blockEvo");
                                        }.bind(this),
                                        500,
                                    );
                                }

                                // As we unblocked everything in case of GB we need to block No Limit City again
                                if (this.callsStore.countryCode === "gb") {
                                    setTimeout(
                                        function () {
                                            this.callsStore.setFilterGameProvidersCommand("blockEvoRelated");
                                        }.bind(this),
                                        1000,
                                    );
                                }
                            }
                        }
                    });

                    // Get VIP Progress
                    // if (this.configStore.config.vipPage) {
                    //     getVipProgress(getCookie("userId"), this.configStore.config.skin, this.myStore, getCookie("accessToken"), this.perCallsStore, this.callsStore, this.$router);
                    // }

                    this.status = "SUCCESS";
                } else {
                    this.status = "FAILED";
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.login-component {
    .link {
        font-size: 13px;
        font-weight: bold;
    }
}
</style>
